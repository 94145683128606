import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { GlobalStyle } from './styles/global';
import { theme } from './styles/materialTheme';
import Routes from './routes/Routes';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <div className="App">
            <ToastContainer />
            <Routes />
          </div>
        </ThemeProvider>
        <GlobalStyle />
      </BrowserRouter>
    </>
  );
}

export default App;
