import { useEffect, useState } from 'react';
import Base from './Base';
import { PostTrialBackoffice } from '../services/backoffice';
import SubmitHandler from '../components/SubmitHandler';
import { PosConfirmacaoLoginGoogle } from '../services/google';

export default function Trial(params) {
  const title = "Experimente Grátis";
  const [json, setJson] = useState({});

  useEffect(() => { 
    if (params) {
      const { code } = params;
      if (code) {
        (async function () {
          const response = await PosConfirmacaoLoginGoogle(code);
          if(response) {
            setJson({
              name: response.name,
              fantasyName: response.name,
              email: response.email,
              trialGoogle: true,
              googleImage: response.picture
            });
          }
        })();
      }
    }
  }, [params, setJson]);
 

  return (
    <Base
      handleSubmit={(e) => SubmitHandler(e, PostTrialBackoffice)}
      title={title}
      isTrial={false}
      json={json}
    />
  );
}