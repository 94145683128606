import { createTheme } from '@mui/material/styles';
import { ptBR } from '@mui/x-data-grid';

export const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#FF6A13',
        dark: '#FF5722',
        contrastText: '#fff',
      },
    },
    components: {
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: '14px',
          },
        },
      },
    },
  },
  ptBR,
);
