import Base from './Base';
import { PostTrialBackoffice } from '../services/backoffice';
import SubmitHandler from '../components/SubmitHandler';

export default function Trial() {
  const title = "Experimente Grátis";

  return (
    <Base
      handleSubmit={(e) => SubmitHandler(e, PostTrialBackoffice)}
      title={title}
      isTrial={true}
    />
  );
}