import { TextField } from '@mui/material';


export default function InputCPFCNPJ(props) {
  let { 
    readOnly,
    id, 
    name, 
    label, 
    autoFocus, 
    InputProps, 
    required,
    onChange,
    value,
} = props;

  const mask = (v) => {
        v = v.replace(/\D/g, "");

        if (v.length > 14) {
          v = v.substr(0, 14);
        }

        if (v.length <= 11) {
          v = v.replace(/(\d{3})(\d)/, "$1.$2")
          v = v.replace(/(\d{3})(\d)/, "$1.$2")
          v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
        } else {
          v = v.replace(/^(\d{2})(\d)/, "$1.$2")
          v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
          v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
          v = v.replace(/(\d{4})(\d)/, "$1-$2")
        }

        return v
      }

      const handleChangeMask = (event) => {
          value = mask(event.target.value);
          const target = { value: value };
          const e = { target };
          onChange(e);
      }

      return (<TextField
        required={required}
        value={value}
        fullWidth
        variant="standard"
        id={id}
        label={label}
        name={name}
        autoFocus={autoFocus}
        onChange={(e) => { handleChangeMask(e);} }
        InputLabelProps= {{
          shrink: true
        }}
        InputProps={{
          ...InputProps,
          readOnly,
          maxLength: 14
        }}
      />)
};
