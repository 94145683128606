import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';

export default function InputMaskField(props) {
    const { 
         mask,
         readOnly,
         id, 
         name, 
         label, 
         InputProps, 
         type,
         onChange,
         onBlur,
         value,
         required,
    } = props;
    

    const configMaskfield = {
      id,
      name,
      label,
      type,
      value,
      InputProps: {
        ...InputProps,
        readOnly,
      },
      InputLabelProps: {
        shrink: true
      },
      mask,
      onChange,
      onBlur,
      required,
    };
    
    return (<InputMask {...configMaskfield} readOnly={readOnly}>
      {(inputProps) => (
        <TextField
          {...inputProps}
          variant="standard"
          fullWidth
        />
      )}
    </InputMask>)
;}