import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';



function HelpIcon({ helpText }) {
  return (
    <Tooltip title={helpText} arrow placement="top">
      <HelpOutlineOutlinedIcon />
    </Tooltip>
  );
}

export default function InputTextField(props) {
  const {
    readOnly,
    id,
    name,
    label,
    autoFocus,
    InputProps,
    type,
    required,
    onChange,
    value,
    showHelpIcon,
    disabled,
    helpText
  } = props;

  return (
    <TextField
      required={required}
      value={value}
      fullWidth
      variant="standard"
      type={type}
      id={id}
      label={label}
      name={name}
      autoFocus={autoFocus}
      onChange={onChange}
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        ...InputProps,
        readOnly,
        disabled,
        endAdornment: showHelpIcon && (
          <InputAdornment position="end">
            <HelpIcon helpText={helpText} />
          </InputAdornment>
        ),
      }}
    />
  );
}