import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Copyright from '../components/Copyright';
import LogoEleve from '../components/LogoEleve';
import SideImage from '../components/SideImage';

export default function NotFound404 () {
    return (
      <Grid container component="main" sx={{ height: '100vh' }}>
        <SideImage />
        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
          <Grid 
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              marginTop: '5%',
            }}
          >
          <LogoEleve />
          </Grid>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Grid 
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography component="h2" variant="h2">
                    Página não encontrada
                </Typography>
              </Grid>
              <Box sx={{ mt: 1 }}>
                  <Grid item xs={12}>
                    <Copyright sx={{ mt: 5 }} />
                  </Grid>
                </Box>
              </Box>
          </Grid>
        </Grid>)
}