import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import GoogleIcon from '@mui/icons-material/Google';


import PasswordStrengthBar from 'react-password-strength-bar';

import Copyright from '../components/Copyright';
import InputTextField from '../components/InputTextField';
import InputCPFCNPJ from '../components/InputCPFCNPJ';
import InputMaskField from '../components/InputMaskField';
import InputPasswordField from '../components/InputPasswordField';
import LogoEleve from '../components/LogoEleve';
import SideImage from '../components/SideImage';
import Loading from '../components/Loading';
import InputCheckboxTerms from '../components/InputCheckboxTerms';
import { GestaoText } from '../components/Loading';
import LoadAddress from '../services/viacep';
import { Google } from '../services/google';

import { STATUS_PASSWORD } from '../utils/constants';

export default function Base(props) {
  const {
    handleSubmit,
    title,
    readOnly,
    json,
    submitButtonDisabled,
    isTrial,
  } = props;
  const [timer, setTimer] = useState(setTimeout(function () { }, 100));
  const [loading, setLoading] = useState(false);

  const [isStrongPassword, setIsStrongPassword] = useState(false);
  const [isStrongPasswordConfirmation, setIsStrongPasswordConfirmation] = useState(false);
  const [haveAcceptedTerms, setHaveAcceptedTerms] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [fantasyName, setFantasyName] = useState('');
  const [cellPhone, setCellPhone] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [address, setAddress] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [municipalityIbgeCode, setMunicipalityIbgeCode] = useState('');
  const [complement, setComplement] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [document, setDocument] = useState('');
  const [email, setEmail] = useState('');
  const [resellerProtheusId, setResellerProtheusId] = useState('');
  const [companyResponsible, setCompanyResponsible] = useState('');
  const [trialGoogle, setTrialGoogle] = useState('');
  const [googleImage, setGoogleImage] = useState('');

  useEffect(() => {
    if (json) {
      if (json.name) setName(json.name);
      if (json.fantasyName) setFantasyName(json.fantasyName);
      if (json.cellPhone) setCellPhone(`${json.cellDDD}${json.cellPhone}`);
      if (json.postalCode) setPostalCode(json.postalCode);
      if (json.address) setAddress(json.address);
      if (json.addressNumber) setAddressNumber(json.addressNumber);
      if (json.state) setState(json.state);
      if (json.city) setCity(json.city);
      if (json.municipalityIbgeCode) setMunicipalityIbgeCode(json.municipalityIbgeCode);
      if (json.neighborhood) setNeighborhood(json.neighborhood);
      if (json.complement) setComplement(json.complement);
      if (json.document) setDocument(json.document);
      if (json.email) setEmail(json.email);
      if (json.resellerProtheusId) setResellerProtheusId(json.resellerProtheusId);
      if (json.companyResponsible) setCompanyResponsible(json.companyResponsible);
      if (json.trialGoogle) setTrialGoogle(json.trialGoogle.toString());
      if (json.googleImage) setGoogleImage(json.googleImage);
    }
  }, [json]);

  const submitForm = async event => {
    event.preventDefault();
    try {
      setLoading(true);
      await handleSubmit(event);
    } catch(e) {
      setLoading(false);
    }
  };

  const callAddress = (e) => {
    clearTimeout(timer);
    setTimer(setTimeout(() => {
      LoadAddress(
        e, setAddress, setCity, setState, setNeighborhood, setComplement, setMunicipalityIbgeCode)
    }, 1000));
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <SideImage />

      {loading && <Loading />}
      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            marginTop: '1%',
          }}
        >
          {(isTrial) ?
            <Avatar onClick={Google} sx={
              {
                backgroundColor: 'white',
                border: '1px solid grey',
                cursor: 'pointer',
                marginLeft: '80%'
              }} >
              <GoogleIcon />
            </Avatar>
            : <Avatar src={googleImage} sx={{ marginLeft: '80%' }}>
              <GoogleIcon />
            </Avatar>
          }
          <LogoEleve width="20%" />
          <GestaoText>
            Gestão
          </GestaoText> 
        </Grid>
        
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography component="h2" variant="h6">
              {title}
            </Typography>
          </Grid>

          <Box component="form" onSubmit={submitForm} sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <InputTextField
                  id="name"
                  label="Razão Social"
                  name="name"
                  readOnly={readOnly}
                  autoFocus
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputTextField
                  id="fantasyName"
                  label="Nome Fantasia"
                  name="fantasyName"
                  readOnly={readOnly}
                  required
                  value={fantasyName}
                  onChange={(e) => setFantasyName(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <InputCPFCNPJ
                  id="document"
                  label="CPF/CNPJ"
                  name="document"
                  readOnly={readOnly}
                  InputProps={{ maxLength: 14, minLength: 11 }}
                  required
                  value={document}
                  onChange={(e) => setDocument(e.target.value)}
                />
              </Grid>
              <Grid item xs={8}>
                <InputTextField
                  id="email"
                  type="email"
                  label="E-mail"
                  name="email"
                  readOnly={readOnly || trialGoogle}
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={8}>
                <InputTextField
                  id="companyResponsible"
                  type="companyResponsible"
                  label="Responsável"
                  name="companyResponsible"
                  readOnly={readOnly}
                  value={companyResponsible}
                  onChange={(e) => setCompanyResponsible(e.target.value)}
                  showHelpIcon
                  helpText="Informe o nome do responsável para contato."
                />
              </Grid>
              <Grid item xs={4}>
                <InputMaskField
                  mask="(99) 99999-9999"
                  readOnly={readOnly}
                  id="cellPhone"
                  label="Celular"
                  name="cellPhone"
                  value={cellPhone}
                  required
                  onChange={(e) => setCellPhone(e.target.value)}
                  />
              </Grid>
              <Grid item xs={6} md={3}>
                <InputMaskField
                  mask="99999-999"
                  readOnly={readOnly}
                  id="postalCode"
                  label="CEP"
                  name="postalCode"
                  value={postalCode}
                  onChange={(e) => {
                    setPostalCode(e.target.value);
                    callAddress(e);
                  }}
                  onBlur={(e) => callAddress(e)}
                  required />
              </Grid>
              <Grid item xs={6} md={3}>
                <InputTextField
                  id="state"
                  label="Estado"
                  name="state"
                  readOnly
                  required
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <InputTextField
                  id="city"
                  label="Cidade"
                  name="city"
                  readOnly
                  required
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <InputTextField
                  id="neighborhood"
                  label="Bairro"
                  name="neighborhood"
                  readOnly={readOnly}
                  required
                  value={neighborhood}
                  onChange={(e) => setNeighborhood(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <InputTextField
                  id="address"
                  label="Endereço"
                  name="address"
                  readOnly={readOnly}
                  required
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                <InputTextField
                  id="addressNumber"
                  label="Número"
                  name="addressNumber"
                  readOnly={readOnly}
                  required
                  value={addressNumber}
                  onChange={(e) => setAddressNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <InputTextField
                  id="complement"
                  label="Complemento"
                  name="complement"
                  readOnly={readOnly}
                  value={complement}
                  onChange={(e) => setComplement(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <InputTextField
                  id="resellerProtheusId"
                  label="Canal"
                  name="resellerProtheusId"
                  readOnly={readOnly}
                  value={resellerProtheusId}
                  onChange={(e) => setResellerProtheusId(e.target.value)}
                  showHelpIcon
                  helpText="Caso você faça parte de uma revenda, informe o seu código."
                />
              </Grid>
              <Grid item xs={6} />
              {(!trialGoogle) ?
                <>
                  <Grid item xs={6}>
                    <InputPasswordField
                      id="password"
                      label="Senha"
                      name="password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <PasswordStrengthBar
                      password={password}
                      minLength={8}
                      onChangeScore={(score) => setIsStrongPassword(score >= 3)}
                      scoreWords={STATUS_PASSWORD}
                      shortScoreWord="Muito curta"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputPasswordField
                      id="confirm-password"
                      label="Confirme a senha"
                      name="confirm-password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    <PasswordStrengthBar
                      password={confirmPassword}
                      minLength={8}
                      onChangeScore={(score) => setIsStrongPasswordConfirmation(score >= 3)}
                      scoreWords={STATUS_PASSWORD}
                      shortScoreWord="Muito curta"
                    />
                  </Grid>
                </> : <></>}
              <input
                type="hidden"
                id="municipalityIbgeCode"
                name="municipalityIbgeCode"
                value={municipalityIbgeCode}
              />
              <input
                type="hidden"
                id="trialGoogle"
                name="trialGoogle"
                value={trialGoogle}
              />
              <Grid item xs={12}>
                <InputCheckboxTerms onClick={setHaveAcceptedTerms} />
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={6}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={submitButtonDisabled || (!trialGoogle && (!isStrongPassword || !isStrongPasswordConfirmation)) || !haveAcceptedTerms}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Começar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Copyright sx={{ mt: 5 }} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>)
}