import io from 'socket.io-client';
import axios from 'axios';
import ErrorHandler from '../components/ErrorHandler';
import { Redirect } from '../services/gateway';

const api = axios.create({
    baseURL: process.env.REACT_APP_URL_GESTAO,
});

async function NotificationToken(email, platformUrl) {
    try {
        return api.get(`/api/v1/trialnotification?email=${email}&platformUrl=${platformUrl}`);
    } catch (e) {
        ErrorHandler(e);
    }
}

export async function NotificationSocket(email, document) {
    const platformUrl = `${document}${process.env.REACT_APP_PLATFORMSUFIX}`;
    
    window.localStorage.setItem('email', email);
    window.localStorage.setItem('documento', document);

    NotificationToken(email, platformUrl).then((res) => { 
        let socket = initSocket(res.data.token);

        socket.on('error', function(error) {
            if (
                error.type === 'UnauthorizedError' ||
                error.code === 'invalid_token'
            ) {
                try {
                    socket = initSocket(res.data.token)
                } catch (e) {
                    ErrorHandler('Token de notificações expirado!');
                }
            }
        });

        const channel = `${process.env.REACT_APP_MANAGERAPPCLIENTID}_${platformUrl}`;
        socket.on(`receivedMessage_${channel}`, receiveMessage);
        socket.on(`readMessage_${channel}`, readMessage);

        socket.emit('login');
    });
}


function initSocket(token) {
    return io(process.env.REACT_APP_NOTIFICATION, {
        query: `token=${token}`,
        forceNew: true
    });
}

function receiveMessage(msg) {
    let message = 'Não foi possível identificar a mensagem de retorno. Por favor, verifique a sua caixa de e-mail e realize o login conforme as orientações recebidas.';
    let messageType = 'info';

    if (!!msg) {
        if (msg?.message) message = msg.message;
        if (msg?.messageType) messageType = msg.messageType.toLowerCase();
    }

    if (messageType === 'success') {
        Redirect(window.localStorage.getItem('documento'), window.localStorage.getItem('email'));
    }

    if (messageType === 'warning') {
        setTimeout(() => window.location.replace(process.env.REACT_APP_INICIALIZACAO || ''), 5000);
        ErrorHandler(message);
    }

    if (messageType === 'info') {
        setTimeout(() => window.location.replace(process.env.REACT_APP_INICIALIZACAO || ''), 5000);
        ErrorHandler(message);
    }
}

function readMessage(msg) {
    ErrorHandler(msg.message);
}