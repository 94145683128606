import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function CheckboxLabel() {
  return (
    <Typography>
      Declaro que li e aceito os <a href="https://cdnmpn.blob.core.windows.net/termos/Termo de Subscrição - Eleve Gestão.pdf" target="_blank" rel="noreferrer">termos e condições de uso.</a>
    </Typography>
  );
}

export default function InputCheckboxTerms({ onClick }) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          onChange={e => onClick(e.target.checked)}
        />
      }
      label={<CheckboxLabel />} />
  );
}
