import Grid from '@mui/material/Grid';
import { SIDE_IMAGE } from '../utils/constants';

export default function SideImage() {
    return <Grid item xs={false} sm={4} md={6}
                sx={{
                backgroundImage: `url(${SIDE_IMAGE})`,
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) =>
                    t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                backgroundSize: 'cover',
                backgroundPosition: 'center',
        }}
  />
}