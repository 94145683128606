import React from "react";
import { Routes as ReactRoutes, Route, useLocation } from 'react-router-dom';
import Trial from '../pages/Trial';
import TrialGoogle from '../pages/TrialGoogle';
import Confirm from '../pages/Confirm';
import Waiting from '../pages/Waiting';
import NotFound404 from '../pages/NotFound404';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Routes() {
    let query = useQuery();
    return (<ReactRoutes>
                <Route path='*' element={<NotFound404 />} />
                <Route path='/' element={<Trial />} />
                <Route path='trial' element={<Trial />} />
                <Route path='confirm' element={<Confirm />} />
                <Route path='confirm/:id' element={<Confirm />} />
                <Route path='logingoogle' element={<TrialGoogle code={query.get("code")}/>}/>
                <Route path='waiting' element={<Waiting documento={query.get("documento")} email={query.get("email")}/>} />
            </ReactRoutes>)
}