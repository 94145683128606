
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export const CardEleve = (props) => {
    const { title, line_1, line_2 } = props;
    return ( <Card sx={{ 'background-color': 'transparent' }} elevation={0}>
        <CardActionArea>
          <CardContent>
            <Typography variant="h4" component="div" color="#FFF">
              {title}
            </Typography>
            <Typography  color="#FFF" variant="h6">
             {line_1}<br/>{line_2}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>)
}