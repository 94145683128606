import ErrorHandler from './ErrorHandler';
import { MD5 } from '../utils/functions';

export default async function SubmitHandler(event, PostMethod) {
  let object = {};

  try {
    let canSubmit = true;

    const data = new FormData(event.currentTarget);

    data.forEach((value, key) => {
      if (key === 'postalCode' || key === 'cellPhone' || key === 'document')
        value = value.replaceAll(/[^\d]/g, '');

      if (key === 'cellPhone') {
        object['cellDDD'] = value.substr(0, 2);
        value = value.substr(2, 9);
      }

      if (key === 'trialGoogle' && value === 'true') object['password'] = MD5(new Date().toString());

      object[key] = value;
    });

    if (data.get('trialGoogle') !== 'true' && object['confirm-password'] !== object['password']) {
      ErrorHandler({ message: { text: 'As senhas não coincidem.', type: 'info' } });
      canSubmit = false;
    }

    if (object['name'].split(' ').length < 2) {
      ErrorHandler({ message: { text: 'Informe o nome completo.', type: 'info' } });
      canSubmit = false;
    }

    if (object['document'].length < 10 || object['document'].length === 12 || object['document'].length === 13 || object['document'].length > 14) {
      ErrorHandler({ message: { text: 'Informe um documento válido.', type: 'info' } });
      canSubmit = false;
    }

    if ((object['cellDDD'] && object['cellDDD'].length < 2) || (object['cellPhone'] && object['cellPhone'].length < 9)) {
      ErrorHandler({ message: { text: 'Informe o celular corretamente.', type: 'info' } });
      canSubmit = false;
    }

    if (!object['cellPhone'] ) {
      delete (object['cellDDD']);
      delete (object['cellPhone']);
    }

    if (object['postalCode'].length < 8) {
      ErrorHandler({ message: { text: 'Informe o CEP corretamente.', type: 'info' } });
      canSubmit = false;
    }

    if (object['companyResponsible'] && object['companyResponsible'].length > 120) {
      ErrorHandler({ message: { text: 'A descrição do responsável não pode ultrapassar 120 caracteres.', type: 'info' } });
      canSubmit = false;
    }

    if (!object['companyResponsible'] ) {
      delete (object['companyResponsible']);
    }

    delete (object['confirm-password']);
    delete (object['trialGoogle']);

    if (canSubmit) {
      let json = JSON.stringify(object);
      let response = await PostMethod(json);

      if (response) {
        window.dataLayer = window.dataLayer || [] ;
        window.dataLayer.push({
          'event': 'gaEvent',
          'eventCategory': 'cadastro-experimentegratis',
          'eventAction': 'envio',
          'eventLabel': 'sucesso',
        });
        window.location.replace(`${process.env.REACT_APP_INICIALIZACAO}/waiting?email=${object['email']}&documento=${object['document']}`);
      }
    }
  } catch (e) {
    ErrorHandler(e);
  }
}