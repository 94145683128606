import axios from 'axios';
import ErrorHandler from '../components/ErrorHandler';

const api = axios.create({
    baseURL: process.env.REACT_APP_URL_BACKOFFICE_API,
});

async function TokenBackoffice() {
    const data = {
        userName: process.env.REACT_APP_BACKOFFICE_USERNAME,
        password: process.env.REACT_APP_BACKOFFICE_PASSWORD,
    };
    try {
        return api.post('/token', data);
    } catch (e) {
        ErrorHandler(e);
    }
}

export async function PostTrialBackoffice(data) {
    return TokenBackoffice().then((res) => {
        return SendForm(data, res.data.token)
    });
}

export async function PostConfirmBackoffice(data) {
    TokenBackoffice().then((res) => { SendForm(data, res.data.token) });
}

async function SendForm(data, token) {
    try {
        return api.post('/client/subscription/trial', data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            });
    } catch (e) {
        ErrorHandler(e);
    }
}

export async function GetClient(id) {
    try {
        return TokenBackoffice().then(res => {
            return SearchClient(id, res.data.token).then(response => {
                return response.data;
            });
        });
    } catch (e) {
        ErrorHandler(e);
    }
}

function SearchClient(id, token) {
    return api.get(`/client/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        }
    });
}

