import axios from 'axios';
import ErrorHandler from '../components/ErrorHandler';

const accountApi = axios.create({
    baseURL: 'https://accounts.google.com',
});

const googleApis = axios.create({
    baseURL: 'https://www.googleapis.com/oauth2/v1'
})

export function Google() {
    var accountGoogleURL = "https://accounts.google.com/o/oauth2/auth?response_type=code&redirect_uri=";
    var userInfoScope = "&scope=https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile&client_id=";
    var googleurl = `${accountGoogleURL}${process.env.REACT_APP_GOOGLE_URL_REDIRECIONAMENTO}${userInfoScope}${process.env.REACT_APP_GOOGLE_CLIENT_ID}`;
    window.location.replace(googleurl);
}

export async function PosConfirmacaoLoginGoogle(code) {
    const params = new URLSearchParams()
    params.append('code', code)
    params.append('client_id', process.env.REACT_APP_GOOGLE_CLIENT_ID)
    params.append('client_secret', process.env.REACT_APP_GOOGLE_CLIENT_SECRET)
    params.append('redirect_uri', process.env.REACT_APP_GOOGLE_URL_REDIRECIONAMENTO)
    params.append('grant_type', 'authorization_code')

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    return accountApi.post('/o/oauth2/token', params, config).then((response) => {
        return LoadGoogleUserData(response.data).then(res => {
            return res.data;
        });
    }).catch((e) => {
        ErrorHandler({ message: { text: 'Não foi possível recuperar os dados deste cliente', type: 'info' } });
        setTimeout(() => window.location.replace(process.env.REACT_APP_INICIALIZACAO || ''), 2000);
    });
}

async function LoadGoogleUserData(response) {
    return googleApis.get(`/userinfo?access_token=${response.access_token}`)
}
