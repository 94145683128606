import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1997;
  background: var(--color-background-loader);
  transition: all 0.3s ease-out;
`;

const Lockscreen = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
`;

export const EleveLoadText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${props => props.marginTop ? props.marginTop : 0};

    p, span {
      font-family: 'Titillium Web';
      font-size: ${props => props.fontSize ? props.fontSize : '30px'};
      color: ${props => props.color ? props.color : '#ff6a13'};
    }

    p {
      padding-top: 120px;
    }
  `;

const ContainerImages = styled.div`
  transform: rotate(30deg);
`;

const FirstItem = styled.div`
  width: 140px;
  height: 43px;
  transform: skew(20deg);
  transform: skew(20deg);
  transform: skew(20deg);
  background: #ff6a13;
  border-radius: 5px;
  margin: 25px;
  animation: eleve-loading 2s linear infinite;
  animation-delay: 1s;

  @keyframes eleve-loading {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
`;

const SecondItem = styled.div`
  width: 140px;
  height: 43px;
  transform: skew(20deg);
  background: #ff6a13;
  border-radius: 5px;
  margin-top: 15px;
  animation: eleve-loading2 2s linear infinite;

  @keyframes eleve-loading2 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
`;

const FirstMiniItem = styled.div`
  width: 45px;
  height: 15px;
  transform: skew(20deg);
  transform: skew(20deg);
  transform: skew(20deg);
  background: #ff6a13;
  border-radius: 1.5px;
  margin: 9px;
  animation: eleve-loading 2s linear infinite;
  animation-delay: 1s;

  @keyframes eleve-loading {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
`;

const SecondMiniItem = styled.div`
  width: 45px;
  height: 15px;
  transform: skew(20deg);
  background: #ff6a13;
  border-radius: 1.5px;
  margin-top: 5px;
  animation: eleve-loading2 2s linear infinite;

  @keyframes eleve-loading2 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
`;


export const GestaoText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.color ? props.color : '#ff6a13'};
    font-weight: bold;
    margin-top: 3%;
    font-size: 20px;
`;

export default function Loading() {
  return (
    <Container>
      <Lockscreen>
        <EleveLoadText>
          <ContainerImages>
            <FirstItem />
            <SecondItem />
          </ContainerImages>
          <p>Aguarde...</p>
        </EleveLoadText>
      </Lockscreen>
    </Container>
  );
};


export function Load(props) {
  const { text, fontSize, color, marginTop } = props;
  return (<EleveLoadText fontSize={fontSize} color={color} marginTop={marginTop}>
            <ContainerImages>
              <FirstMiniItem />
              <SecondMiniItem />
            </ContainerImages>
            <span>{text}</span>
          </EleveLoadText>)
}
