
import { toast } from 'react-toastify';

const toastProps = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export default function ErrorHandler(e) {
    if (e.response && e.response.data) {
        const { errors, errorMessage } = e.response.data;
        if (errors) {
            errors.forEach((error) => { 
                toast(error.message, {
                    type: 'error',
                    ...toastProps
                });
            });
        }
        if (errorMessage) {
            toast(errorMessage, {
                type: 'error',
                ...toastProps
            });
        }
    }

    if (e.message) {
        const { type, text } = e.message;
        toast(text, {
            type,
            ...toastProps
        });
    }

    if (typeof(e) === 'string')
    {
        toast(e, {
            type: 'warning',
            ...toastProps
        });
    }

    throw e;
}