import axios from 'axios';
import ErrorHandler from '../components/ErrorHandler';

const api = axios.create({
    baseURL: process.env.REACT_APP_URL_GESTAO,
});

async function GatewayRedirect(hash) {
    try {
        return api.get(`/api/v1/TrialPlatformRedirect?hash=${hash}`);
    } catch (e) {
        ErrorHandler(e);
    }
}

export async function Redirect(documento, email) {
    GatewayRedirect(`${documento};${email}`).then((res) => {
        const { url } = res.data;
        window.location.replace(url);
    });
}

