/* eslint-disable no-template-curly-in-string */
export const CONTENT_TYPE_JSON = 'application/json';
export const HTTP_AUTHORIZATION = 'Authorization';
export const HTTP_CONTENT_TYPE = 'Content-Type';
export const HTTP_GET = 'GET';
export const HTTP_POST = 'POST';
export const HTTP_PUT = 'PUT';
export const HTTP_DELETE = 'DELETE';

export const CEP = {
  error: 'Não foi possível encontrar o endereço solicitado'
}

export const STATUS_PASSWORD = ['Fraca', 'Fraca', 'Pode melhorar', 'Boa senha', 'Forte'];

export const GATEWAY_CONSTANTS = {
  tokenData: {
    accessToken: 'access_token',
    expiresIn: '.expires',
  },
  grantTypePassword: 'password',
};

export const SIDE_IMAGE = 'https://mpn.azureedge.net/img/fluxo/eleve_start.png';
export const LOGO_ELEVE = 'https://cdnmpn.blob.core.windows.net/img/logo/eleve-logo-by-totvs.png';
export const LOGO_ELEVE_WHITE = 'https://mpn.azureedge.net/img/logo/eleve_white.png'
export const ELEVE_BACKGROUND = 'https://cdnmpn.blob.core.windows.net/img/marketing/trial-background.jpg';

export const UUID_REGEX = '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$';

export const DEFAULT_APP_VALUES = {
  PAGINATION: {
    QUERY_STRING_PAGE_SIZE: 'pageSize',
    QUERY_STRING_PAGE: 'page',
    DEFAULT_PAGE_SIZE: '10',
  },
};

export const APP_MESSAGES = {
  TOAST_SUCCESS_TITLE: 'Sucesso.',
  TOAST_SUCCESS_MESSAGE: 'Requisição processada com sucesso.',
  TOAST_ERROR_TITLE: 'Erro.',
  TOAST_ERROR_MESSAGE: 'Erro ao processar sua requisição.',
  TOAST_PLATFORM_NOT_INSTALLED:
    'Plataforma selecionada não possui o app instalado.',
  TOAST_ERROR_GET_PLATFMORS:
    'Não foi possível recuperar os ambientes associados ao usuário.',
};

export const MASK_FORMAT = {
  CPF: '999.999.999-99',
  CNPJ: '99.999.999/9999-99',
  TELEFONE: '(99) 9999-9999',
  CELULAR: '(99) 99999-9999',
  CEP: '99999-999',
  NCM: '9999.99.99',
  CEST: '99.999.99',
};

export const LOCALE = {
  MIXED: {
    INVALIDO: 'Inválido',
    CAMPO_REQUERIDO: 'Este campo é requerido',
    UM_DESTES: 'Deve ser um dos seguintes valores: ${values}',
    UM_DESTES_NAO: 'Não pode ser um dos seguintes valores: ${values}',
  },
  STRING: {
    TAMANHO: 'Digite exatamente ${length} caracteres',
    MIN: 'Digite pelo menos ${min} caracteres',
    MAX: 'Digite no máximo ${max} caracteres',
    ESPACO: 'Não deve conter espaços no início ou no fim.',
    EMAIL: 'Informe um e-mail válido',
    URL: 'Informe uma URL válida',
    MINUSCULO: 'Deve ser digitado em minúsculo',
    MAIUSCULO: 'Deve ser digitado em maiúsculo',
  },
  NUMBER: {
    MIN: 'Deve ser no mínimo ${min}',
    MAX: 'Deve ser no máximo ${max}',
    MENOR_QUE: 'Deve ser menor que ${less}',
    MAIOR_QUE: 'Deve ser maior que ${more}',
    POSITIVO: 'Deve ser um número posítivo',
    NEGATIVO: 'Deve ser um número negativo',
    INTEIRO: 'Deve ser um número inteiro',
  },
  DATE: {
    MIN: 'Deve ser maior que a data ${min}',
    MAX: 'Deve ser menor que a data ${max}',
  },
  ARRAY: {
    MIN: 'Deve ter no mínimo ${min} itens',
    MAX: 'Deve ter no máximo ${max} itens',
  },
  NAO_ENCONTRADO: 'Nenhum registro encontrado',
};
