import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import Base from './Base';
import SubmitHandler from '../components/SubmitHandler';
import ErrorHandler from '../components/ErrorHandler';
import { UUID_REGEX } from '../utils/constants';
import { PostConfirmBackoffice, GetClient } from '../services/backoffice';

export default function Trial() {
  const [client, setClient] = useState({});

  const title = "Confirmação";
  const { id } = useParams();

  const validateId = useCallback(() => {
    const result = id && id.match(UUID_REGEX);
    return !(result && result.length);
  }, [id]);


  useEffect(() => {
    if (validateId()) {
      ErrorHandler({ message: { text: 'Não foi possível recuperar os dados deste cliente', type: 'info' } });
      return;
    }
    (async function () {
      setClient(await GetClient(id));
    })();
  }, [id, setClient, validateId])

  return (
    <Base
      handleSubmit={(e) => SubmitHandler(e, PostConfirmBackoffice)}
      title={title}
      json={client}
      readOnly
      submitButtonDisabled={validateId()}
      isTrial={false}
    />
  );
}