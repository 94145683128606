import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root{
    --color-new-green:#00BB7E;
    --color-new-orange:#FF6A13;
    --color-new-grey:#4F5355;

    --color-deep-orange:#ff5722;
    --color-orange:#FF6A13;
    --color-light-orange:#FF7F32;

    --color-deep-blue:#003458;
    --color-blue:#00678b;
    --color-light-blue:#03a9f4;
    --color-totvs-blue:#0c9abe;
    --color-blue-grey:#394f5a;

    --color-deep-green:#27601B;
    --color-green:#00b279;
    --color-light-green:#8bc34a;

    --color-deep-purple:#673ab7;
    --color-purple:#9c27b0;

    --color-deep-red:#6f1200;
    --color-red:#db3a34;
    --color-pink:#e91e63;

    --color-indigo:#3f51b5;
    --color-cyan:#00bcd4;
    --color-teal:#009688;
    --color-lime:#cddc39;
    --color-yellow:#E0B002;
    --color-amber:#E9A130;
    --color-brown:#795548;
    --color-grey:#8e999b;

    --color-transparent: transparent;
    --color-shadow:#0d181a;
    --color-white:#fafafb;
    --color-black:#2c3739;
    --color-light-grey:#f2f2f2;
    --color-background-loader: rgba(13, 24, 26 ,0.93);
    --color-datatable-row-selected: rgba(255,106,19,0.2);

    --color-notification-warning: rgba(224, 176, 2, 0.05);
    --color-notification-success: rgba(0,178,121,0.05);

    --menu-icon-size: 1.5rem;
    --menu-text-color:#dedee4;
    --menu-hover-color: rgba(255, 106, 19, 0.1);
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  svg {
    color: var(--color-new-orange);
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    background: var(--color-light-grey);
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button, a, table {
    font-family: 'Titillium Web', sans-serif;
    font-weight: 400;
  }

  button {
    cursor: pointer;
  }
`;
