import axios from 'axios';
import ErrorHandler from '../components/ErrorHandler';
import { CEP } from '../utils/constants';

const api = axios.create({
    baseURL: process.env.REACT_APP_VIACEP,
});

async function CEPSearch(postalCode) {
    try {
        return api.get(`${postalCode}/json`);
    } catch (e) {
        ErrorHandler(e);
    }
}

export default function LoadAddress(
    e,
    setAddress,
    setCity,
    setState,
    setNeighborhood,
    setComplement,
    setMunicipalityIbgeCode,
) {
    try {
        const { value } = e.target;
        CEPSearch(value).then((resp) => {
            if (resp.data.erro) {
                ResetAddress(setAddress, setCity, setState, setNeighborhood, setComplement);
                return ErrorHandler({ message: { text: CEP.error, type: 'info' } });
            }
            if (resp.data) {
                const { bairro, complemento, localidade, logradouro, uf, ibge } = resp.data;
                setAddress(logradouro);
                setCity(localidade);    
                setState(uf);
                setNeighborhood(bairro);
                setComplement(complemento);
                setMunicipalityIbgeCode(ibge);
            }
        });
    } catch {
        ResetAddress(setAddress, setCity, setState, setNeighborhood, setComplement);
        ErrorHandler({ message: { text: CEP.error, type: 'info' } });
    }
}

function ResetAddress(
    setAddress,
    setCity,
    setState,
    setNeighborhood,
    setComplement) {
    setAddress('');
    setCity('');
    setState('');
    setNeighborhood('');
    setComplement('');
}