import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { useEffect } from 'react';

import { CardEleve } from '../components/Card';
import { Load, EleveLoadText } from '../components/Loading';
import { NotificationSocket } from '../services/socket';
import LogoEleve from '../components/LogoEleve';
import { ELEVE_BACKGROUND } from '../utils/constants';

export default function Waiting(params) {
    useEffect(() => { 
      if (params) {
        const { email, documento } = params;
        if (email && documento) {
          NotificationSocket(email, documento);
        }
      }
    }, [params]);
    
    return (
      <>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid item xs={12} component={Paper} elevation={0} square sx=
        {{  backgroundImage: `url(${ELEVE_BACKGROUND})`,
           'background-repeat': 'no-repeat',
           'background-size': 'cover',
           'overflow': 'auto' 
        }}>
            <Box>
              <LogoEleve isWhite width="7%" style={{ marginLeft: '2%', position: 'absolute' }}/>
              <Load 
                fontSize="25px" 
                color="#FFF"
                text="Estamos trabalhando na criação do seu ambiente." 
                marginTop="2%">
              </Load>
              <EleveLoadText fontSize="20px" color="#FFF">
                <br /> <span> Aguarde alguns segundos para ter acesso ao sistema, em breve você será redirecionado automaticamente.</span>
              </EleveLoadText> 
            </Box>
            <Box
              sx={{
                p: 2,
                m: 4,
                display: 'grid',
                gap: 2,
                maxHeight: 300,
              }}
            >
              <CardEleve 
                title="Emita notas e organize ordens de serviço."
                line_1="Emita notas (NFe e NFSe) em poucos cliques e esteja em dia com as obrigações fiscais."
                line_2="Controle ordens de serviços e pedidos, evitando perda de prazos."
              />
              <CardEleve 
                title="Controle o seu fluxo de caixa com facilidade."
                line_1="Descubra onde você ganha e onde perde dinheiro."
                line_2="Visualize com facilidade as contas a pagar e a receber."
              />
              <CardEleve 
                title="Controle compras realizadas e estoque."
                line_1="Faça importações rápidas de arquivos XML para cadastrar produtos no sistema."
                line_2="Realize inventários e tenha controle total do seu estoque."
              />
              <CardEleve 
                title="Aumente sua produtividade com as nossas APIs liberadas"
                line_1="Integre os nossos sistemas a aplicativos de mercado para facilitar o dia a dia da sua operação."
                line_2="Torne a sua gestão financeira muito mais digital e precisa por meio das nossas APIs"
              />
            </Box>
        </Grid>
      </Grid>
    </>)
}