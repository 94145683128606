import { useState, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';



function Icon({ visible, toggleVisibility }) {
  return (
    <Button onClick={toggleVisibility} variant="text">
      {visible ? (
        <VisibilityOffOutlinedIcon />
      ) : (
        <VisibilityOutlinedIcon />
      )}
    </Button>
  );
}

export default function InputPasswordField(props) {
  const [visible, setVisible] = useState(false);

  const {
    readOnly,
    id,
    name,
    label,
    autoFocus,
    InputProps,
    required,
    onChange,
    value,
  } = props;

  const toggleVisibility = useCallback(() => {
    setVisible(visibility => !visibility);
  }, []);

  return (
    <TextField
      required={required}
      value={value}
      fullWidth
      variant="standard"
      type={visible ? 'text' : 'password'}
      id={id}
      label={label}
      name={name}
      autoFocus={autoFocus}
      onChange={onChange}
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        ...InputProps,
        readOnly,
        endAdornment: (
          <InputAdornment position="end">
            <Icon visible={visible} toggleVisibility={toggleVisibility} />
          </InputAdornment>
        ),
      }}
    />
  );
}